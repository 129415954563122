import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    items: [],
    verified: null,
    successData: null
}
const cableSlice = createSlice({
    name: 'cable',
    initialState,

    reducers: {
        setCableProducts: (state, action) => {
            state.items = action.payload
        },
        setVerifycard: (state, action)=>{
            state.verified = action.payload
        },
        setSuccessData: (state, action) => {
            state.successData = action.payload
        }
    }
})


export const { setVerifycard, setCableProducts, setSuccessData } = cableSlice.actions

export const selectCableProduct = state => state.cable.items
export const selectVerifyCard = state => state.cable.verified
export const selectSuccessData = state => state.cable.successData

export default cableSlice.reducer