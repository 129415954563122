import { Visibility } from '@mui/icons-material'
import React, {Fragment, useState} from 'react'
import {useSelector} from 'react-redux'
import { selectTransactions } from '../app/slices/transactionSlice'
import { selectToken } from '../app/slices/userSlice'
import { ToUpperCase, renderTotal } from './global/func'
import Currency from 'react-currency-formatter'
import { PRODUCTID_IDS } from '../constant'

const TransactionList = () => {
    const token = useSelector(selectToken)
    
    const transactions = useSelector(selectTransactions) 
    const [selected, setSelected] = useState() 
    // console.log(transactions)
    return (
        <Fragment>

            <table className="transaction_table">
                <thead id="s_n">
                    <th>S/N</th>
                    <th>Product</th>
                    <th>Amount (<span>&#x20A6;</span>)</th>
                    <th>Cost Price (<span>&#x20A6;</span>)</th>
                    <th>Number</th>
                    <th>Transaction ID</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Action</th>
                    
                </thead>
                
                <tbody>
                    {
                        transactions?.length > 0 && transactions?.data?.map( (tx,index) =>{
                            const {amount, tranId, createdAt, productId, memberId, accNumber, narration, createData, tranStatus,productName, tranType, clientCommision}= tx
                           

                            return (
                                <Fragment key= {index}>
                                    <tr id="amt"
                                    
                                    className={selected==tranId ? 'trHoverActive': ''}
                                    >
                                        <td> {index + 1}</td>
                                        <td> { productName } </td>
                                        <td>
                                            <p 
                                                className={tranType == "dr"   }
                                            >
                                            { amount  } 
                                           
                                            </p> 
                                        </td>

                                        <td>
                                            <p 
                                                className={tranType == "dr" ? "error": "success"  }
                                            >
                                            { amount - clientCommision } 
                                           
                                            </p> 
                                        </td>

                                        <td> {createData? createData[0]?.number : ''} </td>
                                        <td>{tranId}</td>
                                    
                                        <td>{new Date(createdAt).toUTCString().split('GMT')[0]}</td>
                                        <td>
                                            <p
                                                className={tranStatus == 1 ? 'success' : 'error'}
                                            >
                                                {
                                                    tranStatus == 1 ? 'Completed' : tranStatus == 2 ? 'Pending' :  tranStatus == 3 ? "onHold" :  tranStatus == 0 ? "failed" :  tranStatus == 4 ? "refunded":  'indispute'
                                                }
                                            </p>
                                            
                                        </td>
                                        <td style={{position: 'absolute', display: 'flex'}}>
                                            <div className="table_ActionContainer">
                                            
                                            {
                                                tranId != selected ?
                                                <Fragment>
                                                   
                                                    <Visibility 
                                                        style={{
                                                            width: 30
                                                        }}
                                                        onClick={() =>setSelected(tranId)}
                                                    />
                                                </Fragment>
                                                :
                                                <p className='closePopup'
                                                        onClick={() =>setSelected('')}
                                                > &times; </p>
                                            }
                                            </div>
                                            {
                                                    createData?.length > 0 &&  tranId == selected &&
                                                    
                                                    <div className='dataPopup'>

                                                        <ul>
                                                        <p
                                                            className={tranStatus == 2 ? 'success': 'paywith'}
                                                        >{createData[0]?.message}</p>
                                                            {
                                                                createData[0]?.status &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Status</p>
                                                                        <p> {createData[0]?.status} </p>
                                                                </div>
                                                            }
                                                            {
                                                                productId &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Product</p>
                                                                        <p> {PRODUCTID_IDS[productId]}</p>
                                                                </div>
                                                            }
                                                            {
                                                                createData[0]?.purchasedUnits &&
                                                                <div className='createDataInfo'>
                                                                    <p>Purchased Units</p>
                                                                    <p> {createData[0]?.purchasedUnits} </p>
                                                                </div>
                                                            }

                                                            {
                                                                createData[0]?.accountNumber &&
                                                                <div className='createDataInfo'>
                                                                    <p>Account Number</p>
                                                                    <p> {createData[0]?.accountNumber} </p>
                                                                </div>
                                                            }
                                                            {
                                                                createData[0]?.amountPaid &&
                                                                <div className='createDataInfo'>
                                                                    <p>Amount Paid</p>
                                                                    <p>
                                                                        <Currency 
                                                                            currency='NGN'
                                                                            quantity= {createData[0]?.amountPaid} 
                                                                        />
                                                                    </p>
                                                                </div>
                                                            }
                                                            {
                                                                createData[0]?.settlementAmount &&
                                                                <div className='createDataInfo'>
                                                                    <p>Settlement Amount</p>
                                                                    <p>
                                                                        <Currency 
                                                                            currency='NGN'
                                                                            quantity= {createData[0]?.amountPaid - 70} 
                                                                        />
                                                                    </p>
                                                                </div>
                                                            }
                                                            {
                                                                createData[0]?.senderAccountName &&
                                                                <Fragment>
                                                                <p className='success'>Transfer From:</p>
                                                                <hr />
                                                                <div className='createDataInfo'>
                                                                    <p>Account Name</p>
                                                                    <p> {createData[0]?.senderAccountName} </p>
                                                                </div>
                                                                <div className='createDataInfo'>
                                                                    <p>Account Number</p>
                                                                    <p> {createData[0]?.senderAccountNumber} </p>
                                                                </div>

                                                                </Fragment>
                                                            }
                                                            

                                                            {
                                                                createData[0]?.amount &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Amount</p>
                                                                        <p
                                                                            className={tranType =='dr' ? 'paywith': 'success'}
                                                                        >
                                                                            <Currency
                                                                                currency='NGN'
                                                                                quantity={createData[0]?.amount} 
                                                                            />
                                                                        </p>
                                                                </div>
                                                            }

                                                            {
                                                                createData[0]?.number &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Number</p>
                                                                        <p> {createData[0]?.number} </p>
                                                                </div>
                                                            }
                                                            {
                                                                createData[0]?.token &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Token No</p>
                                                                        <p className='paywith'> {createData[0]?.token} </p>
                                                                </div>
                                                            }
                                                            {
                                                                createData[0]?.tranId &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Ref. No</p>
                                                                        <p> {createData[0]?.tranId} </p>
                                                                </div>
                                                            }

                                                            {
                                                                createData[0]?.tariffRate &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Tariff Rate</p>
                                                                        <p> {createData[0]?.tariffRate} </p>
                                                                </div>
                                                            }

                                                            {
                                                                createData[0]?.customerName &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Name</p>
                                                                        <p> {createData[0]?.customerName} </p>
                                                                </div>
                                                            }
                                                            {
                                                                createData[0]?.customerAddress &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Address</p>
                                                                        <p> {createData[0]?.customerAddress} </p>
                                                                </div>
                                                            }
                                                            
                                                            {
                                                                createdAt &&
                                                                <div className='createDataInfo'>
                                                                    
                                                                        <p>Date</p>
                                                                        <p> {new Date(createdAt).toUTCString().split('GMT')} </p>
                                                                </div>
                                                            }
                                                            
                                                        </ul>
                                                    </div>
                                                
                                                }
                                        </td>
                                    </tr>
                                </Fragment>
                            )
                        })
                    }
                </tbody>
            </table>
            
            <div className='tableTotal shadow'>
                <h3>
                    {
                        transactions?.length > 0 &&

                        <Currency
                            currency='NGN'
                            quantity={renderTotal(transactions?.data)}
                        />
                    }
                </h3>
                </div>
            
        </Fragment>
    )
}

export default TransactionList
