import React from 'react'

const Copyright = () => {
    
    const updateT = new Date().getFullYear()

    return (

        <div className="copyright">
            Copyright 2018-{updateT} Everyday Transact All Rights Reserved
        </div> 

    )

}

export default Copyright