import { React, Fragment, useState } from "react"
import FeedHeader from "./global/FeedHeader";

const FundingContent = () => {

    const [active, setActive] = useState("BANK")

    return (

        <Fragment>

            <div className="dashboardCenter">
                
                <FeedHeader title="Funding" />

                <div className="dataMain">

                    <div className="dataLeftWrap">
                
                        <div className="dataLeft" onClick={()=> setActive("BANK")}>
                            <div className="dataLCont">
                                <div className="rechargeImg">
                                    <img src={process.env.PUBLIC_URL + "./img/bank.png"} alt="img"/>  
                                </div>
                                <p>Bank</p>
                            </div>
                        </div>
                
                        <div className="dataLeft" onClick={()=> setActive("WALLET ID")}>
                            <div className="dataLCont">
                                <div className="rechargeImg">
                                    <img src={process.env.PUBLIC_URL + "./img/wallet.png"} alt="img"/>  
                                </div>
                                <p>Wallet</p>
                            </div>
                        </div>

                    </div>

                    <div className="airtimeRight">
                        
                        <div className="activeTxt">{active}</div>

                        {active === "BANK" && (

                            <div>

                                <div className="userIptContent">

                                    <div className="userIpt">
                                        <input 
                                            type="text"
                                            placeholder="Card Number"
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="cardDetails">
                                        
                                        <div className="userIpt">
                                            <input 
                                                type="text"
                                                placeholder="CVV"
                                                autoComplete="off"
                                            />
                                        </div>

                                        <div className="userIpt">
                                            <input 
                                                type="text"
                                                placeholder="MM-DD-YY"
                                                autoComplete="off"
                                            />
                                        </div>
                                        
                                    </div>

                                    <div className="userIpt">
                                        <input 
                                            type="text"
                                            placeholder="Pin"
                                            autoComplete="off"
                                        />
                                    </div>

                                </div>

                                <div className="proBtn">
                                    <button>Proceed</button>
                                </div>

                            </div>
                        )}

                        {active === "WALLET ID" && (
                            
                            <div>

                                <div className="userIptContent">

                                    <div className="userIpt">
                                        <input 
                                            type="text"
                                            placeholder="22101229283"
                                            readOnly
                                        />
                                    </div>

                                </div>

                            </div>

                        )}

                    </div>

                </div>

            </div>

        </Fragment>

    )

}

export default FundingContent