import React, { Fragment } from 'react'
import { noRecordImg } from '../../constant'

function NoRecordFound({message}) {
    return (
       
       <Fragment>
           <div className="noRecord" >
                <img src={noRecordImg} alt=""/>
                    <h3>
                    {message}
                </h3>
            </div>
       </Fragment>
    )
}

export default NoRecordFound
