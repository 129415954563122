import { XIcon } from '@heroicons/react/outline'
import React, { Fragment } from 'react'

const Popover = ({children, setOpen, title, large, medium, small }) => {

    return (

        <Fragment>

            <div className="modalContainer">

                <div className='modalCloseBtn' onClick={() =>setOpen()}>

                    {title}

                    <XIcon
                        style={{
                            width: 40
                        }}
                    />

                </div>

                <div className={large ? 'popOverLarge': medium ? 'popOverMedium': small ? 'popOverSmall': 'popOver' }>
                    
                    {children}
                    
                </div>

            </div>

        </Fragment>
    )
}

export default Popover
