import { React, Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectToken } from "../app/slices/userSlice"
import { getAllTransactions } from "../customHooks/transaction"
import NoRecordFound from "../components/global/NoRecordFound" 
import TransactionList from "./TransactionList"
import PagenationFooter from "./PagenationFooter"
import { selectTransactions, setTransactions } from "../app/slices/transactionSlice"
import axios from "axios"
import FeedHeader from "./global/FeedHeader";
import { setError, setIsLoading } from "../app/slices/uiSlice"

const TrasactionContent = () => {
    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const [active, setActive] = useState()
    const [tranId, setTrandId] = useState()
    const transactions = useSelector(selectTransactions)
    const [isCustom, setIsCustom] = useState(false)
    const [filter, setFilter] = useState({
        endDate: '',
        startDate: '',
    })
    const [txType, setTxType] = useState()
    const [txStatus, setTxStatus] = useState()
    const [pageCount, setPageCount] = useState()
    const [body, setBody] = useState({
        page: 1,
        limit: 10,
    })

    const getTransactionByTxType = ()=>{
        setIsLoading(true)
        axios.get(`admin/transaction/cr_dr/${body.page}/${body?.limit}/${txType}`, {
            headers: {
                Authorization:`ApiKey ${token}`
            }
        }).then(result => {
            const {data} = result
            setIsLoading(false)
            setPageCount(data?.length)
            if(data?.data?.length == 0) return dispatch(setTransactions(''))
            return dispatch(setTransactions(data))
        }).catch(error =>{
            setIsLoading(false)
            dispatch(setTransactions(''))
            return error
        })
    }

    const getTransactionByTranStatus = async()=>{
        try {
            setIsLoading(true)
            const { data } = await axios.get(`/transaction/transtatus/${txStatus}`, {
                headers: {
                    Authorization:`ApiKey ${token}`
                }
            })
                
                setIsLoading(false)
                setPageCount(data?.length)
                if(data?.data?.length == 0) return dispatch(setTransactions(''))
                return dispatch(setTransactions(data))
        } catch (error) {
            setIsLoading(false)
            dispatch(setTransactions(''))
        }
       
        
    }

    const getTransactionByDate = ()=>{
        const { startDate, endDate } = filter
        setIsLoading(true)
        axios.get(`/transaction/date/${body.page}/${body?.limit}/${startDate}/${endDate}`, {
            headers: {
                Authorization:`ApiKey ${token}`
            }
        }).then(result => {
            const {data} = result
            setIsLoading(false)
            setPageCount(data?.length)
            if(data?.data?.length == 0) return dispatch(setTransactions(''))
            return dispatch(setTransactions(data))
        }).catch(error =>{
            setIsLoading(false)
            dispatch(setTransactions(''))
            return error
        })
    }

    const getTxByRefTxId = async () => {
        try {
            const { data, status } = await axios.get(`/transaction/${tranId}`, {
                headers: {
                    Authorization: `ApiKey ${token}`
                }
            })

            if(status == 200) return dispatch(setTransactions(data))
            dispatch(setError(data))
        } catch (error) {
            dispatch(setError(error.response?.data?.message))
        }
    }

    useEffect(() =>{
        if(active ==1) return getAllTransactions(token, body.page, body.limit, dispatch)

    },[active, body])

    useEffect(() =>{
        if(!txType) return
            getTransactionByTxType()
    }, [txType])

    useEffect(() =>{
        if(!txStatus) return
            getTransactionByTranStatus()
    }, [txStatus])

    useEffect(() =>{
        const { startDate, endDate } = filter
        if(!startDate || !endDate) return
            getTransactionByDate()
    }, [filter])

    useEffect(() => {
        if(!tranId) return
        getTxByRefTxId()
    }, [tranId])


    useEffect(() => {
        getAllTransactions(token, 1, 20, dispatch)
    }, [token]) 
    return (

        <Fragment>

            <div className="dashboardCenter">
                
                <FeedHeader title="Transactions" />

                <div className="transactionMain">

                    <div 
                        className={active === 1? "transactItemsActive" : "transactItems"} 
                        onClick={()=> setActive(1)}
                    >All Transactions</div>

                    <div 
                        className={active === 2? "transactItemsActive" : "transactItems"} 
                        onClick={()=> (
                            setActive(2),
                            setTxStatus(1)
                        )}
                    >Completed Transactions</div>

                    <div 
                        className={active === 3? "transactItemsActive" : "transactItems"} 
                        onClick={()=> (
                            setActive(3),
                            setTxStatus(2)
                        )}
                    >Pending Transactions</div>

                    <div 
                        className={active === 4? "transactItemsActive" : "transactItems"} 
                        onClick={()=> (
                            setActive(4),
                            setTxStatus(3)
                        )}
                    >Canceled Transactions</div>

                </div>

                <TransactionList  />
                {
                    !transactions?.length > 0 &&
                    <NoRecordFound />
                }
                <PagenationFooter

                    body={body}
                    tranId={tranId}
                    setBody={setBody}
                    isCustom={isCustom}
                    pageCount={pageCount}
                    setIsCustom={setIsCustom}
                    setTrandId={setTrandId}
                    filterDate={filter}
                    transactions={transactions}
                    setFilterDate={setFilter}
                />
            </div>

        </Fragment>

    )

}

export default TrasactionContent