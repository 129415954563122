import React, { Fragment } from 'react'
import {MenuIcon} from '@heroicons/react/solid'
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectToggle } from '../../app/slices/uiSlice';
import { setIsMobile, setToggle } from '../../app/slices/uiSlice';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';


const FeedHeader = ({title}) => {
    const dispatch = useDispatch()
    const toggle = useSelector(selectToggle)

    return (

        <Fragment>

            <div className="searchRow">
                    
                <div className='title'>
                    <span className="componentName">
                        {title}
                        <p className="orangeBorder" />
                    </span>
                </div>

                {/* <div className="searchWrap">
                    <input 
                        type="search"
                        name="search"
                        placeholder="Search..."
                    />
                </div> */}

                <div className="notificationWrap">
                    <div className={ "notifIcon"}>
                        <NotificationsNoneIcon className="notifImg"/>
                    </div>
                    
                    <div className='mediaScreem'>
                        
                        <IconButton
                            onClick={() =>dispatch(setIsMobile(true))}
                        >
                            <MenuIcon  />
                        </IconButton>
                        
                    </div>
                {
                    !toggle &&
                    <div className='lgScreen'>
                    
                        <div className='iconContainer '>
                            <IconButton
                                onClick={() =>dispatch(setToggle(!toggle))}
                            >
                                <MenuIcon  />
                            </IconButton>
                        </div>
                    
                    </div>
                }
                </div>

            </div> 
             
        </Fragment>
    )
}

export default FeedHeader