import { React, Fragment, useEffect } from "react"
import {Link, useNavigate} from "react-router-dom"
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AsyncStorage from '@react-native-async-storage/async-storage'
import {useDispatch, useSelector} from 'react-redux'
import { setToken } from "../app/slices/userSlice";
import { selectIsMobile } from "../app/slices/uiSlice";
import UserCard from '../components/global/UserCard'
import { getUser } from "../customHooks/user";
const DashboardLeft = ({mobile}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch() 
    const isMobile = useSelector(selectIsMobile)
    const handleLogOut = async() => {
        await AsyncStorage.removeItem('accessToken')
        dispatch(setToken(null))
        return navigate('/')
    }

    useEffect(() => {
        async function handleAuth () {
            const tk = await AsyncStorage.getItem('accessToken')
            if(!tk) return handleLogOut()
            dispatch(setToken(tk))
            getUser(tk, dispatch)
        }
        handleAuth()
    }, [])

    

    return (

        <Fragment>

            <div className={`${mobile ? 'dashboardLeft' : 'dashboardLeft_wrapper '}`}>

                {
                    isMobile &&
                    <UserCard mobile />
                }
                {   !isMobile &&
                    <a href="/dashboard" className="logoContainer">
                        <div className="logoo">
                            <img src={process.env.PUBLIC_URL + "./logo/edtransmain.png"} alt="logo" />
                        </div>   
                    </a>
                }

                <div className="navOptions">

                    <a  href="/dashboard" className="optionsWrap">
                        <div className="optionIcon">
                            <DashboardCustomizeOutlinedIcon className="iconImg"/>
                        </div>  
                        <div className="optionName">Dashboard</div>    
                    </a>

                    <Link to={"/Airtime"} className="optionsWrap">
                        <div className="optionIcon">
                            <PhoneAndroidOutlinedIcon className="iconImg"/>
                        </div>    
                        <div className="optionName">Airtime</div>    
                    </Link>

                    <Link to={"/Data"} className="optionsWrap">
                        <div className="optionIcon">
                            <BarChartOutlinedIcon className="iconImg"/>
                        </div>      
                        <div className="optionName">Data</div>    
                    </Link>

                    <Link to={"/Electricity"} className="optionsWrap">
                        <div className="optionIcon">
                            <TipsAndUpdatesIcon className="iconImg"/>
                        </div>     
                        <div className="optionName">Electricity</div>    
                    </Link>

                    <Link to={"/Cable"} className="optionsWrap">
                        <div className="optionIcon">
                            <DesktopWindowsOutlinedIcon className="iconImg"/>
                        </div>     
                        <div className="optionName">Cable Tv</div>    
                    </Link>

                    <Link to={"/Funding"} className="optionsWrap">
                        <div className="optionIcon">
                            <AccountBalanceWalletIcon className="iconImg"/>
                        </div>       
                        <div className="optionName">Funding</div>    
                    </Link>

                    <Link to={"/Transaction"} className="optionsWrap">
                        <div className="optionIcon">
                            <ShowChartOutlinedIcon className="iconImg"/>
                        </div>       
                        <div className="optionName">Transactions</div>    
                    </Link>

                    <Link to={"/Profile"} className="optionsWrap">
                        <div className="optionIcon">
                            <AccountCircleOutlinedIcon />
                        </div>    
                        <div className="optionName">Profile</div>    
                    </Link>
                    
                </div>

                <div 
                    className="logOutWrapper"
                    onClick={handleLogOut}
                >
                    <div className="logOutBtn">Log Out</div>
                </div>

            </div>

        </Fragment>

    )

}

export default DashboardLeft