const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    token: null,
    data: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.data = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        }
    }
})


export const { setUser, setToken } = userSlice.actions

export const selectUser = state => state.user.data 
export const selectToken = state => state.user.token 

export default userSlice.reducer