import React, { Fragment } from 'react'

const PagenationFooter = ({ 
    body, 
    tranId, 
    setBody, 
    isCustom, 
    setIsCustom, 
    transactions, 
    setTrandId, 
    filterDate, 
    setFilterDate, 
    pageCount,
    commisions
}) => {
    return (
        <Fragment>
            <div  className = "pagenation_container shadow">
                <div
                    onClick = {() =>setBody({
                        ...body,
                        page: body?.page - 1
                    })}
                    className="pagenationBtn"
                >
                    <button
                    type="button"
                    disabled = {body?.page == 1}
                    >Previous</button> 
                </div>

                {/* Custom Search */}
                <div className='customTitle'
                    onClick={() =>setIsCustom(!isCustom)}
                >
                    <p className={isCustom ? 'success': ''}>CUSTOM</p>
                </div>
                {
                    isCustom &&
                    <div 
                    className = "pagenation_input_Container"
                >  
                    {
                        !commisions ? <p>Filter</p>
                        :
                        <p>Filter By</p>
                    }
                    
                    {
                        !commisions &&
                        <input 
                            type="text"
                            value={tranId}
                            placeholder='Transaction ID'
                            onChange={e =>setTrandId(e.target.value)}
                        />
                    }
                    <p> Date: </p>
                    <p>From</p>
                    <input 
                        type="date"
                        
                        value={filterDate.startDate}
                        onChange={e => setFilterDate({
                            ...filterDate,
                            startDate: e.target.value
                        })}
                        />
                    <p>To</p>
                    <input 
                        type="date" 
                        value={filterDate.endDate}
                         
                        onChange={e => setFilterDate({
                            ...filterDate,
                            endDate: e.target.value
                        })}
                    />
                </div>

                }
                <div className="pagecounter_info">
                    <p className='activePage'>Active 
                        <div>{body?.page}</div>
                    </p>
                    <p>{ body?.page > 1 ? 'Pages' : 'Page'}</p>
                    <p>{body?.page}</p>
                    {/* <p> {pageCounter} </p> */}
                    <p> {`${pageCount >= 18 ? `-${pageCount}` :  ''}  `} </p>
                </div>
                <div 
                    
                    className="pagenationBtn"
                >
                    <button
                        type="button"
                        onClick = {() =>setBody({
                            ...body,
                            page: body?.page + 1
                        })}
                        disabled = {transactions?.length <10}
                    >Next</button> 

                </div>
            </div>
                                
        </Fragment>
    )
}

export default PagenationFooter