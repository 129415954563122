import { configureStore } from "@reduxjs/toolkit";
import uiReducer from './slices/uiSlice'
import userReducer from './slices/userSlice'
import cableReducer from './slices/cableSlice'
import transactionReducer from './slices/transactionSlice'

export const store = configureStore({
   reducer: {
    ui: uiReducer,
    user: userReducer,
    cable: cableReducer,
    transaction: transactionReducer
   }
})