const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    items: [],
    item: null
}

const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTransactions: (state, action) => {
            state.items = action.payload
        },
        setSelectedTransaction: (state, action) => {
            state.item = action.payload
        }
    }
})

export const { setTransactions, setSelectedTransaction } = transactionSlice.actions

export const selectTransactions = state => state.transaction.items 
export const selectSelectedTransaction = state => state.transaction.item

export default transactionSlice.reducer