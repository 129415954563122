const { createSlice } = require("@reduxjs/toolkit")

const initialState = {
    Error: null,
    success: null,
    isLoading: null,
    toggle:true,
    isMobile: false
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        setToggle: (state, action) => {
            state.toggle = action.payload 
        },
        setError: (state, action) => {
            state.Error = action.payload
        },
        setSuccess: (state, action) => {
            state.success = action.payload
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload
        },
        setIsMobile: (state, action) => {
            state.isMobile = action.payload
        }
    }
})

export const { setError, setToggle, setSuccess, setIsMobile, setIsLoading } = uiSlice.actions

export const selectError = state => state.ui.Error 
export const selectSuccess = state => state.ui.success 
export const selectToggle = state => state.ui.toggle
export const selectIsMobile = state => state.ui.isMobile
export const selectIsLoading = state => state.ui.isLoading

export default uiSlice.reducer