import { React, Fragment } from "react"
import DashboardLeft from "../components/DashboardLeft"
import DashboardRight from "../components/DashboardRight"
import DataContent from "../components/DataContent"
import { useSelector } from "react-redux"
import { selectToggle } from "../app/slices/uiSlice"

const Data = () => {

    const toggle = useSelector(selectToggle)

    return (

        <Fragment>

            <div className="dashboardWrapper">
                
                <DashboardLeft />
            
                <main className="mainWrapper">

                    <div className="centerContainer">  

                        <DataContent />
                                         
                    </div> 

                    {
                        toggle &&
                        <div className="rightWrap">
                            <DashboardRight /> 
                        </div>                          
                    }               
                </main>

            </div>

        </Fragment>

    )

}

export default Data