import { React, Fragment, useEffect, useState } from 'react'
import 'react-calendar/dist/Calendar.css';
import DatePicker from './DatePicker'
import {useDispatch, useSelector} from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useNavigate } from 'react-router-dom';
import { ToUpperCase } from './global/func';
import { setToken, selectUser } from '../app/slices/userSlice';
import { getUser } from '../customHooks/user';
import { selectTransactions } from '../app/slices/transactionSlice';
import { MenuIcon } from '@heroicons/react/solid'
import { selectToggle, setToggle } from '../app/slices/uiSlice';
import { IconButton } from '@mui/material';
import UserCard from './global/UserCard';

const DashboardRight = () => {
    const dispatch = useDispatch() 
    const navigate = useNavigate()
    const toggle = useSelector(selectToggle)
    const user = useSelector(selectUser)
    useEffect( async () => {
        const  token = await AsyncStorage.getItem('accessToken')
        if(!token) return navigate('/')
        return (
            dispatch(setToken(token)),
            getUser(token, dispatch)
        )
   
    },[])

    const transactions = useSelector(selectTransactions)
    const handleTXFilter = (data) => {
        if(data?.length < 0 ) return 
        const txDebit = data?.filter(item =>item.tranType == 'dr')
        const txCredit = data?.filter(item =>item.tranType == 'cr')

        return {
            totalDR: txDebit?.reduce((a, b) =>a+b.amount, 0),
            totalCR: txCredit?.reduce((a, b) =>a+b.amount, 0),
            
        }

    }
    
    return (

        <Fragment>

            <div className={`${toggle ? 'asideBar' : 'hidden'}`}>
               
                <div className='lgScreen'>
                    <UserCard />
                </div>
                
                <div className="amtDetails">

                    <div className="totalDeb">
                        <p>Total debit</p>
                        {
                            transactions?.length > 0 &&
                            <h4 className="credit">&#8358; 
                                {handleTXFilter(transactions.data).totalDR?.toLocaleString()}
                            </h4>
                        }
                    </div>
                    
                    <div className="totalDeb">
                        <p>Total Credit</p>
                        {
                            transactions?.length > 0 &&
                            <h4 className="credit">&#8358; 
                                {handleTXFilter(transactions.data).totalCR?.toLocaleString()}
                            </h4>
                        }
                        
                    </div>
                    
                   {
                       user &&
                        <div className="totalDeb">
                        <p>Current balance</p>
                        <h4>&#8358;{user?.walletId?.walledBalance?.toLocaleString()}</h4>
                        <h4>&#8358;{user?.walletId?.walletCommision?.toLocaleString()}</h4>                        
                        </div>
                    }
                </div>

                <div className="dateWrapper">

                    <DatePicker />

                </div>

            </div>

        </Fragment>

    )
}

export default DashboardRight