import axios from "axios"
import { setCableProducts, setSuccessData, setVerifycard } from "../app/slices/cableSlice"
import { setError, setIsLoading, setSuccess } from "../app/slices/uiSlice"

export const getCablesProducts = async (token, dispatch, body) => {
    try {
        const { data, status} = await axios.post(`/product/get_package`, body, {
            headers: {
                Authorization: `ApiKey ${token}`
            }
        })
        if(status == 200) dispatch(setCableProducts(data))
        dispatch(setError(data.message))
    } catch (error) {
        dispatch(setError(error?.response?.message?.data))
    }
}

export const handleCableVeryfication = async(token, url, body, dispatch) => {
    // try{
        dispatch(setError(null), setSuccess(null))
        dispatch(setSuccess(null))
        dispatch(setIsLoading(true))
        const {data, status, statusText } = await axios.post(url, body, {
        headers: {
            Authorization: `ApiKey ${token}`
        }})
             
            if(status !==200) return dispatch(setError(statusText))
            dispatch(setVerifycard(data))
            // dispatch(setSuccess('Success'))

            dispatch(setIsLoading(false))
   
//    } catch (error) {
//        dispatch(setIsLoading(false))
//        console.log(error.response?.data?.message)
//        dispatch(setError(error.response?.data?.message))
//    }
}

export const handleCableSubscription = async(url, token, body, dispatch, setData) => {
    try {
        dispatch(setIsLoading(true))
        const {data, status} = await axios.post(url, body, {
            headers: {
                Authorization: `ApiKey ${token}`
            }
        })
        if(status == 200) return (
            dispatch(setIsLoading(null)),
            dispatch(setSuccessData(data)),
            dispatch(setSuccess(data?.message))
        )
        dispatch(setError(data?.message))
    } catch (error) {
        dispatch(setIsLoading(null))
        dispatch(setError(error.response?.data?.message))
    }
}