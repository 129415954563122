import { Close } from '@mui/icons-material'
import React, { Fragment } from 'react'
import {useDispatch} from 'react-redux'
import { setError, setSuccess } from '../../app/slices/uiSlice'


const ToolTip = ({message, error, success}) => {
    const dispatch = useDispatch() 
    const handleClose = () => {
        dispatch(setError(null))
        dispatch(setSuccess(null))
    }
    return (
        <Fragment>
            <div
                className='toolTip effect'
                style={{
                    borderLeft: error? '8px solid red' : '8px solid #20c997'
                }}
            >
                <h3> {message} </h3>
                
                <div
                    className='btn'
                    onClick={handleClose}
                >
                    <Close />
                </div>
            </div>
        </Fragment>
    )
}

export default ToolTip
