import { React, useState, Fragment } from "react"
import Chart from "react-apexcharts"
import { useSelector } from "react-redux";
import { selectTransactions } from "../app/slices/transactionSlice";

const Charts = () => {

    const transactions = useSelector(selectTransactions)
    const handleTXFilter = (data) => {
        if(data?.length < 0 ) return 
        const txPending = data?.filter(item =>item.tranStatus == 2)
        const txCompleted = data?.filter(item =>item.tranStatus == 1)
        const txCanceled = data?.filter(item =>item.tranStatus == 3)

        return {
            totalPending: txPending?.map(item =>item.amount),
            totalCanceled: txCanceled?.map(item =>item.amount),
            totalCompleted: txCompleted?.map(item =>item.amount)
        }

    }
    const [chartDetail, setChartDetail] = useState({

        options: {
            chart: {
                id: "basic-bar"
            },
            xaxis: {
                // categories: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"]
                categories: transactions?.length > 0 && transactions?.data.map(item =>{
                    const {createdAt} = item
                    return new Date(createdAt).toLocaleDateString()
                })
            }
        },


        series: [
            {
                name: "Pending",
                data: handleTXFilter(transactions?.data).totalPending
            },
            {
                name: "Completed",
                data: handleTXFilter(transactions?.data).totalCompleted
            },
            {
                name: "Canceled",
                data: handleTXFilter(transactions?.data).totalCanceled
            }
        ]

    })

    return (

        <Fragment>
            
            <div>
                <Chart
                    options={chartDetail.options}
                    series={chartDetail.series}
                    type="line"
                    width="98%"
                />
            </div>
            
        </Fragment>

    )

}

export default Charts