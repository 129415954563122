import { React, Fragment, useState } from "react"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch, useSelector } from "react-redux";
import { selectSuccessData, selectVerifyCard, setSuccessData, setVerifycard } from "../app/slices/cableSlice";
import { selectError, selectIsLoading, selectSuccess, setError, setSuccess } from "../app/slices/uiSlice";
import { Button, Divider, IconButton, Tooltip as MuiTooltip } from "@mui/material";
import { ResetTvRounded, VerifiedOutlined } from "@mui/icons-material";
import Popover from "./global/Popover";
import { handleCableSubscription, handleCableVeryfication } from "../customHooks/cableHook";
import { selectToken } from "../app/slices/userSlice";
import ToolTip from "./global/Tooltip";
import FeedHeader from "./global/FeedHeader";

const ELECTRICITIES = [
    {
        id: 1,
        name: 'AEDC',
        image: process.env.PUBLIC_URL + "./img/abj.png"
    },
    {
        id: 2,
        name: 'BEDC',
        image: process.env.PUBLIC_URL + "./img/bedc.png",
    },
    {
        id: 3,
        name: 'EKO',
        image: process.env.PUBLIC_URL + "./img/eko.png",
    },
    {
        id: 4,
        name: 'IBEDC',
        image: process.env.PUBLIC_URL + "./img/ibdc.png"
    },
    {
        id: 5,
        name: 'IKEJA',
        image: process.env.PUBLIC_URL + "./img/ikeja.png",
    },
    {
        id: 6,
        name: 'JED',
        image: process.env.PUBLIC_URL + "./img/jos.png",
    },
    {
        id: 7,
        name: 'KEDC',
        image: process.env.PUBLIC_URL + "./img/kaduna.png"
    },
    {
        id: 8,
        name: 'PHED',
        image: process.env.PUBLIC_URL + "./img/ph.png"
    }
]

const RenderProduct = ({name, active, image, onClick}) => {
    return (
        <Fragment>
            <div className={`airtimeLeft ${active == name ? 'active activeBorder': ''}`} onClick={()=> onClick()}>
                <div className="airtimeLCont">
                    <div className="rechargeImg">
                        <img src={image} alt="img"/>  
                    </div>
                    <p>{name}</p>
                </div>
                <div className="aicone">
                    <div className="rechargeIcon">
                        <ShoppingCartIcon />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const ElectricityContent = () => {
    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const Error = useSelector(selectError)
    const success = useSelector(selectSuccess)
    const isLoading = useSelector(selectIsLoading)
    const successData = useSelector(selectSuccessData)
    const verifiedcard = useSelector(selectVerifyCard)
    const [active, setActive] = useState()
    const [body, setBody] = useState({
        image: '',
        number: '',
        amount: '',
        requestType: ''
    })

    const handleDispatch = (msg, action) => {
        return dispatch(action(msg))
    }

    const handleProceed = async () => {
        const { number, requestType } = body
        if(!active) return handleDispatch('Electricity Is Required', setError)
        if(!number) return handleDispatch('Metre Number Is Required', setError)
        const agent = active?.toLocaleLowerCase()

        const url = `electricity/${agent}/verify`
        
        handleCableVeryfication(
            token, url, 
            {number, requestType}, dispatch
        )
        
    }

    const handlePayCable = () => {

        const { number,amount, requestType } = body
        const agent = active?.toLocaleLowerCase()
        const url = `electricity/${agent}/exchange`

        handleCableSubscription (
            url, token,
            {
                number,
                tranId: "",
                requestType,
                amount: parseInt(amount),
            }, dispatch, setBody
        )
    }

    return (

        <Fragment>
            {
                Error &&
                <ToolTip error message={Error} />
            }
            {
                success &&
                <ToolTip success message={success} />
            }
            {   verifiedcard &&
                <Popover 
                    small
                    setOpen={() =>(
                            dispatch(setSuccessData(null)),
                            dispatch(setVerifycard(null))
                        )
                    }
                >
                    <section
                        className="cardContainer"
                    >
                        <div
                            className="cardData"
                        >
                            <section className="cardHeader">
                                <VerifiedOutlined />
                            </section>
                            
                            <section className="cardHeader">
                               <img src={body?.image} />
                            </section>
                            
                            <section className="padding" />
                            <section className="cardHeader">
                                <h3>Details</h3>
                            </section>
                            <Divider />
                            
                           { 
                                verifiedcard?.customerName &&
                                <div>
                                    <p>Name:</p>
                                    <p> {verifiedcard?.customerName} </p>
                                </div>
                            }
                            {
                                verifiedcard?.tariff &&
                                <div>
                                    <p>Tariff:</p>
                                    <p> {verifiedcard?.tariff} </p>
                                </div>
                            }
                            {
                                verifiedcard?.outStanding &&
                                <div>
                                    <p>Out Standing:</p>
                                    <p> {verifiedcard?.outStanding} </p>
                                </div>
                            }
                            {
                                verifiedcard?.customerAddress &&
                                <div>
                                    <p>Address:</p>
                                    <p> {verifiedcard?.customerAddress} </p>
                                </div>
                            } 
                            {
                                successData &&
                                <Fragment>
                                    {successData?.responseBody?.tariffRate &&
                                        <div>
                                            <p>Tariff Rate:</p>
                                            <p>
                                                <strong>
                                                {successData?.responseBody?.tariffRate}
                                                </strong>
                                            </p>
                                        </div>
                                    }
                                    {successData?.responseBody?.purchasedUnits &&
                                        <div>
                                            <p>Purchase Unit:</p>
                                            <p> 
                                                <strong>
                                                {successData?.responseBody?.purchasedUnits}
                                                </strong>
                                            </p>
                                        </div>
                                    }
                                    {successData?.responseBody?.token &&
                                        <div>
                                            <p>Token:</p>
                                            <h3 className="active">
                                                <strong>{successData?.responseBody?.token}</strong>
                                            </h3>
                                        </div>
                                    }
                                </Fragment>
                            }                          
                            
                            <Divider />
                           
                            <Fragment>
                                {
                                    successData &&
                                    <section className="cardHeader">
                                        {   successData?.product &&
                                            <h6 className="active">{successData?.product} </h6>
                                        }
                                        
                                        <h3 className="success"> {successData?.message} </h3>
                                    </section>
                                }

                                {    
                                    !successData && 
                                    <Fragment>
                                        <section className="cardHeader">
                                            <Button
                                                size="large"
                                                variant="contained"
                                                onClick={handlePayCable}
                                            >
                                                {isLoading ? 'Pay...' : 'Pay'}
                                            </Button>
                                        </section>
                                    </Fragment>
                                }
                            </Fragment>
                            <section className="cardFooter">
                                <MuiTooltip title='Reset and return'>
                                   <dir>
                                   <IconButton
                                        onClick={() => (
                                            dispatch(setSuccessData(null)),
                                            dispatch(setSuccess(null)),
                                            dispatch(setVerifycard(null))
                                        )}
                                   >
                                        <ResetTvRounded />
                                    </IconButton>
                                   </dir>
                                </MuiTooltip>
                            </section>
                            
                        </div>
                    </section>
                       
                </Popover>
            }
            <div className="dashboardCenter">

                <FeedHeader title="Electricity" />

                <div className="dataMain">

                    <div className="dataLeftWrap">
                
                        {
                            ELECTRICITIES?.map(item => {
                                return <RenderProduct
                                    {...item}
                                    active={active}
                                    onClick={() =>(
                                        setActive(item.name),
                                        setBody({
                                            ...body,
                                            image: item.image
                                        })
                                    )}
                                />
                            })
                        }

                    </div>

                    <div className="airtimeRight">
                        
                    

                        <div className="userIptContent">

                            <div className="userIpt">
                                <input 
                                    type="text"
                                    name="number"
                                    value={body.number}
                                    onChange={e =>setBody({
                                        ...body,
                                        number: e.target.value
                                    })}
                                    placeholder="Meter number"
                                    autoComplete="off"
                                />

                                <input 
                                    type="number"
                                    name="amount"
                                    value={body.amount}
                                    placeholder="Amount"
                                    autoComplete="off"
                                    onChange={e =>setBody({
                                        ...body,
                                        amount: e.target.value
                                    })}
                                />

                            </div>

                            <div className="userIpt">
                            <select 
                                name="requestType"
                                value={body.requestType}
                                onChange={e => setBody({
                                    ...body,
                                    requestType: e.target.value
                                })}
                            >
                                <option value="">Select</option>
                                <option value="postpaid">Postpaid</option>
                                <option value="prepaid">Prepaid</option>
                            </select>
                            </div>

                        </div>

                        <div className="proBtn">
                            <button
                                onClick={handleProceed}
                            >
                                 {isLoading ? 'Proceeding...': 'Proceed'}
                            </button>
                        </div>

                    </div>

                </div>

            </div>

        </Fragment>

    )

}

export default ElectricityContent