import React from 'react';
import '../src/App.css'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from "../src/route/App"
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
