import axios from "axios"
import { setError } from "../app/slices/uiSlice"
import { setUser } from "../app/slices/userSlice"

export const getUser = async(token, dispatch) =>{
    try {
        const { data, status } = await axios.get('/user/authenticated', {
            headers: {
                Authorization: `ApiKey ${token}`
            }
        })
        if(status == 200) return dispatch(setUser(data))
    } catch (error) {
        if(error) return setError(error.response.data?.message)
    }
}