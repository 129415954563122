
import Copyright from "../components/Copyright"
import {React, Fragment, useState} from "react"
import "../css/login.css"
import {useDispatch, useSelector} from 'react-redux'
import { selectError, setError, setIsLoading, setSuccess } from "../app/slices/uiSlice"
import axios from "axios"
import { setToken } from "../app/slices/userSlice"
import ToolTip from "../components/global/Tooltip"
import { useNavigate } from "react-router-dom"
import AsyncStorage from '@react-native-async-storage/async-storage'

const Login = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const Error = useSelector(selectError)
    const [body, setBody] = useState({
        email: '',
        password: ''
    }) 
    const handleLogIn = async(e) => {
        dispatch(setError(null))
        setTimeout(() => {
            dispatch(setError(null))
            dispatch(setSuccess(null))
        }, 7000);
        e.preventDefault()
        try {
            dispatch(setIsLoading(true))
            const { data, status } = await axios.post(`/user/auth`, body)
            if(status == 200) return (
                navigate('/dashboard'),
                dispatch(setIsLoading(false)),
                dispatch(setToken(data.accessToken)),
                AsyncStorage.setItem('accessToken', data.accessToken)

            )
            dispatch(setError(data?.response?.data.message))
        } catch (error) {
            if(error) return dispatch(setError(error?.response?.data?.message))
        }
    }
    return (

        <Fragment>
            {
                Error &&
                <ToolTip
                    error 
                    message={Error}
                />
            }

            <div className="blueHeader"></div>
            
            <div className="login_wrapper">

                <div className="comp_logo">
                    <img src={process.env.PUBLIC_URL + "./logo/edtransmain.png"} alt="company" />
                </div>

                <form className="login_form" onSubmit={e =>handleLogIn(e)}>

                    <div className="login_inpt">
                        <input 
                            type="text"
                            name="username"
                            value={body.email}
                            placeholder="Username or email"
                            autoComplete="off"
                            onChange={e =>setBody({
                                ...body,
                                email: e.target.value
                            })}
                        />    
                    </div>

                    <div className="login_inpt">
                        <input 
                            type="password"
                            name="password"
                            value={body.password}
                            placeholder="Password"
                            autoComplete="off"
                            onChange={e =>setBody({
                                ...body,
                                password: e.target.value
                            })}
                        />    
                    </div>

                    <div className="check_details">

                        <div className="check_cont">
                            <input 
                                type="checkbox"
                            /> 
                            <div>Remember me</div>   
                        </div>   

                        <div className="forgot_pass">Forgot password?</div>    
                    </div>

                    <div className="_btn">
                        <button
                            onClick={e =>handleLogIn(e)}
                        >Login</button>
                    </div>

                </form> 

                <div className="no_accnt">
                    <p>Not a member? <span>Register now <b className="arrow">&#x2192;</b></span></p>    
                </div>


                <Copyright />
                
            </div>

        </Fragment>

    )

}

export default Login