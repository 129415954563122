import { React, Fragment, useState } from "react"
import Calendar from "react-calendar";

const DatePicker = () => {

    const[date, setDate] = useState(
        new Date()
    )

    const onChange = ()=> {
        setDate(date)
    }

    return (

        <Fragment>
            
            <div className="reactCalender">

                <Calendar 
                    className="calennn"
                    onChange={onChange} 
                    value={date}                 
                />

            </div>

        </Fragment>

    )

}

export default DatePicker