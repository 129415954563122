import React from "react"
import "../css/index.css"
import Data from "../pages/Data"
import Electricity from "../pages/Electricity"
import Profile from "../pages/Profile"
import Transaction from "../pages/Transaction"
import Airtime from "../pages/Airtime"
import Login from "../pages/Login"
import Dashboard from "../pages/Dashboard"
import Cable from "../pages/Cable"
import Funding from "../pages/Funding"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import axios from "axios"
import { useSelector } from "react-redux"
import { selectIsMobile } from "../app/slices/uiSlice"
import DashboardLeft from "../components/DashboardLeft"
import Drawer from "../components/global/Drawer"

const App = () => {
    const isMobile = useSelector(selectIsMobile)
   // https://api.everydaytransact.com/api/v2
    axios.defaults.baseURL = 'https://api.everydaytransact.com/api/v2'
    return (

        <Router>
            {   
                isMobile &&
                <Drawer>
                    <DashboardLeft mobile />
                </Drawer>
            }
            <Routes>
                <Route 
                    path={"/"}
                    element={<Login />}
                />
                <Route 
                    path={"/dashboard"}
                    element={<Dashboard />}
                />
            
                <Route 
                    path={"/Airtime"}
                    element={<Airtime />}
                />
            
                <Route 
                    path={"/Data"}
                    element={<Data />}
                />
            
                <Route 
                    path={"/Electricity"}
                    element={<Electricity />}
                />
           
                <Route 
                    path={"/Cable"}
                    element={<Cable />}
                />
            
                <Route 
                    path={"/Transaction"}
                    element={<Transaction />}
                />
            
                <Route 
                    path={"/Funding"}
                    element={<Funding />}
                />
            
                <Route 
                    path={"/Profile"}
                    element={<Profile />}
                />
            </Routes>

        </Router>
        

        )

}

export default App