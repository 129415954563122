export const ToUpperCase = (text) =>{
    if(!text) return ''
    const letter = text.toString().replace(/\w/, c => c.toUpperCase())

    return letter
}

export const renderTotal = (data) =>{
    const total = data?.reduce((a, b) => a + b.amount, 0)
    return total
}