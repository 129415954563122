import { React, Fragment, useState } from "react"
import DashboardLeft from "../components/DashboardLeft"
import DashboardRight from "../components/DashboardRight"
import ElectricityContent from "../components/ElectricityContent"
import { useSelector } from "react-redux"
import { selectToggle } from "../app/slices/uiSlice"

const Electricity = () => {

    const toggle = useSelector(selectToggle)

    return (

        <Fragment>

            <div className="dashboardWrapper">
            
                <DashboardLeft />
           
                <main className="mainWrapper">

                    <div className="centerContainer">

                        <ElectricityContent />

                    </div> 

                    {
                        toggle &&
                        <div className="rightWrap">
                            <DashboardRight /> 
                        </div>
                    }     
                                                
                                    
                </main>
            </div>

        </Fragment>

    )

}

export default Electricity