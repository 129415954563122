import avatar from './static/images/avatar.jpeg'
import pendingImg from './static/images/pending.png'
import successImg from './static/images/success.png'
import noRecordImg from './static/images/noRecord.svg'
import view_logoImg from './static/images/veiw_logo.svg'
import loadingImg from './static/images/loading.gif'
import appLogo from './static/images/rsgp.png'
const PRODUCTID_IDS = 
   {

       // funding 
   '9000': 'Funding From',
   '1000': 'Providus Funding',
   '2000': 'Wema Funding',
   '1001': 'Wallet',

   '3000': 'Reg Commision From :',
   '4000': 'Upgrade commision From : ',

        '100': 'MTN Airtime',
        '200': 'GLO Airtime',
        '400': '9Mobile Airtime',
        '300': 'AIRTEL Airtime',
        '500': 'SMILE Airtime',
        // cable
        '600': 'DSTV',
        '700': 'GOTV',
         '800': 'STARTIMES',
        // data
         '101': 'MTN Direct Data',
         '107': 'MTN SME Data',
         '102': 'GLO Direct Data',
         '103': 'AIRTEL Direct Data',
         '104': '9Mobile Direct Data',
        '105': 'Smile Bundle',
        '106': 'Spectranet Data',

        // education 
        '900': 'WAEC',
        // Electricity
        '110': 'AEDC',
        '120': 'JED',
        '130': 'PHED',
        '140':  'KEDC',
        '150': 'KEDCO',
        '160': 'EKEDC',
        '170': 'IKEDC',
        '180': 'IBEDC'

   }


 // const CABLE_ENDPOINT = 'http://localhost:5000/api/v2/cableTv'

export {
   avatar,
   appLogo,
   pendingImg,
   successImg,
  // CABLE_ENDPOINT,
   noRecordImg,
   view_logoImg,
   loadingImg,
   PRODUCTID_IDS
}