import { React, Fragment, useState } from "react"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { getCablesProducts, handleCableSubscription, handleCableVeryfication } from "../customHooks/cableHook";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../app/slices/userSlice";
import { selectCableProduct, selectSuccessData, selectVerifyCard, setSuccessData, setVerifycard } from "../app/slices/cableSlice";
import { selectError, selectIsLoading, selectSuccess, setError, setIsLoading, setSuccess } from "../app/slices/uiSlice";
import Tooltip from '../components/global/Tooltip'
import Popover from "./global/Popover";
import { Button, Divider, IconButton, Tooltip as MuiTooltip } from "@mui/material";
import { CheckCircle, ResetTvRounded, VerifiedOutlined } from "@mui/icons-material";
import FeedHeader from "./global/FeedHeader";

const PRODUCTS = [
    {
        id: 1,
        name: 'DSTV',
        image: process.env.PUBLIC_URL + "./img/dstv2.jpg"
    },
    {
        id: 2,
        name: 'GOTV',
        image: process.env.PUBLIC_URL + "./img/Gotv-Payment.jpg"
    },
    {
        id: 3,
        name: 'STARTIMES',
        image: process.env.PUBLIC_URL + "./img/Startimes-Subscription.jpg"
    }
]

const RenderProduct = ({name, image, active, index, lIndex, onClick}) => {

    console.log(lIndex)

    return (
        <Fragment>
            <div 
                className={`airtimeLeft ${active == name ? 'active activeBorder': ''}`} 
                onClick={()=> onClick()}
                style={{
                    width: index == lIndex ?  lIndex % 2 ? '' : '100%' : ''
                }}
            >
                <div className="airtimeLCont">
                    <div className="rechargeImg">
                        <img src={image} />  
                    </div>
                    <p>{name}</p>
                </div>
                <div className="aicone">
                    <div className="rechargeIcon">
                        <ShoppingCartIcon />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const CableContent = () => {

    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const [active, setActive] = useState()
    const Error = useSelector(selectError)
    const success = useSelector(selectSuccess)
    const successData = useSelector(selectSuccessData)
    const verifiedcard = useSelector(selectVerifyCard)
    const isLoading = useSelector(selectIsLoading)
    const packages = useSelector(selectCableProduct)
    const [body, setBody] = useState({
        image: '',
        number: '',
        amount: '',
        package: '',
        packageId: '',
        invoicePeriod: '',
        paymentType: 'renewPackage',
        requestType: 'VALIDATE_NUMBER'
    })

    const handleDispatch = (msg, action) => {
        return dispatch(action(msg))
    }

    const handleProceed = async () => {
        const { number, requestType, invoicePeriod } = body
        if(!active) return handleDispatch('Cable TV Is Required', setError)
        if(!number) return handleDispatch('Smart Card Number Is Required', setError)
        if(!invoicePeriod) return handleDispatch('Duration Is Required', setError)
        const url = `cableTv/${active}/verify`
        handleCableVeryfication(
            token, url, 
            {number, requestType, invoicePeriod}, dispatch
        )
        
    }
    const handlePayCable = async () => {
        const { number, amount, packageId, requestType, invoicePeriod, paymentType } = body
       const tranIddd = Date.now()/1000;

        if(!active) return handleDispatch('Cable TV Is Required', setError)
        if(!number) return handleDispatch('Smart Card Number Is Required', setError)
        if(!invoicePeriod) return handleDispatch('Duration Is Required', setError)
        const url = active == 'STARTIMES' ? `cableTv/${active}/renewPackage` : `cableTv/${active}/${paymentType}`
        dispatch(setError(null))
        handleCableSubscription(
             url, token,
           
            {   
                number,
                amount,
                packageId,
                tranId: tranIddd.toString(),
                invoicePeriod: 1,
                requestType:"STANDARD",
                monthsPaidFor: parseInt(invoicePeriod)
            },
            dispatch, setBody
        )
        
    }
    return (

        <Fragment>

            {
                Error &&
                <Tooltip error message={Error} />
            }
            {
                success &&
                <Tooltip success message={success} />
            }
            {   verifiedcard &&
                <Popover 
                    small
                    setOpen={() =>(
                            dispatch(setSuccessData(null)),
                            dispatch(setVerifycard(null))
                        )
                    }
                >
                    <section className="cardContainer">

                        <div className="cardData">

                            <section className="cardHeader">
                                <VerifiedOutlined />
                            </section>
                            
                            <section className="cardHeader">
                               <img src={body?.image} />
                            </section>
                            
                            <section className="padding" />

                            <section className="cardHeader">
                                <h3>Details</h3>
                            </section>

                            <Divider />

                            <div>
                                <p>Amount:</p>
                                <p className="error"> 
                                   <strong>
                                    <span>&#x20A6; </span>
                                    {body.amount?.toLocaleString()} 
                                   </strong>
                                </p>
                            </div>

                           { 
                                verifiedcard?.firstName &&
                                <div>
                                    <p>First Name:</p>
                                    <p> {verifiedcard?.firstName} </p>
                                </div>
                            }
                            {
                                verifiedcard?.lastName &&
                                <div>
                                    <p>Last Name:</p>
                                    <p> {verifiedcard?.lastName} </p>
                                </div>
                            }
                            {
                                verifiedcard?.customerName &&
                                <div>
                                    <p>Name:</p>
                                    <p> {verifiedcard?.customerName} </p>
                                </div>
                            }
                            {
                                verifiedcard?.balance &&
                                <div>
                                    <p>Balance:</p>
                                    <p className="success"> 
                                        <strong>
                                        <span>&#x20A6; </span>
                                            {verifiedcard?.balance?.toLocaleString()}
                                        </strong>
                                     </p>
                                </div>
                            }
                            {
                                verifiedcard?.customerNumber &&
                                <div>
                                    <p>Smart Card Number:</p>
                                    <p>
                                        <strong>
                                            {verifiedcard?.customerNumber}
                                        </strong>
                                    </p>
                                </div>
                            }
                            {
                               body?.package &&
                                <div>
                                    <p>Package:</p>
                                    <p>
                                        <strong>
                                            {body.package}
                                        </strong>
                                    </p>
                                </div>
                            }
                            {   verifiedcard?.invoicePeriod &&
                                <div>
                                    <p>Subscription Duration:</p>
                                    <p> {verifiedcard?.invoicePeriod}</p>
                                </div>
                            }
                            {
                                verifiedcard?.dueDate &&
                                <div>
                                    <p>Due Date:</p>
                                    <p> 
                                        <strong>
                                            {new Date(verifiedcard?.dueDate).toLocaleDateString()}
                                        </strong>
                                    </p>
                                </div>
                            }
                            <Divider />
                            {}
                            <Fragment>
                                {
                                    successData &&
                                    <section className="cardHeader">
                                        <h3 className="success"> {successData?.message} </h3>
                                    </section>
                                }

                                {    
                                    !successData && 
                                    <Fragment>
                                        <section className="cardHeader">
                                            <Button
                                                size="large"
                                                variant="contained"
                                                onClick={handlePayCable}
                                            >
                                                {isLoading ? 'Pay...' : 'Pay'}
                                            </Button>
                                        </section>
                                    </Fragment>
                                }
                                </Fragment>

                            <section className="cardFooter">

                                <MuiTooltip title='Reset and return'>
                                   <dir>
                                   <IconButton
                                        onClick={() => (
                                            dispatch(setSuccessData(null)),
                                            dispatch(setSuccess(null)),
                                            dispatch(setVerifycard(null))
                                        )}
                                   >
                                        <ResetTvRounded />
                                    </IconButton>
                                   </dir>
                                </MuiTooltip>

                            </section>
                            
                        </div>

                    </section>
                       
                </Popover>
            }

            <div className="dashboardCenter">

                <FeedHeader title="Cable Tv" />

                <div className="dataMain">

                    <div className="dataLeftWrap">
                        {
                            PRODUCTS?.map((item, index) => {
                                return <RenderProduct 
                                    {...item}
                                    key={item.id}
                                    active={active}
                                    index={index}
                                    lIndex={PRODUCTS?.length -1}
                                    onClick={() => (
                                            setBody({
                                                ...body, image: item.image
                                            }),
                                            setActive(item.name),
                                            getCablesProducts(token, dispatch, {productType: item.name})
                                        )
                                    }
                                />
                            })
                        }
                       
                    </div>
                    
                    <div className="airtimeRight"> 

                        {packages?.length > 0 && (
                            
                            <div className="productContainer">
                                {packages?.map((item, index)=> {
                                    const {name, amount, productId} = item
                                    return (
                                        <div 
                                            onClick={() =>(
                                                setBody({
                                                    ...body,
                                                    amount: amount,
                                                    package: name,
                                                    packageId: productId
                                                })
                                            )}
                                            key={index} 
                                            className={`dataItem ${productId == body.packageId ? 'active activeBorder': ''}`}>
                                            <p>{name}</p>
                                            <p className="success"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            > <span>&#x20A6;</span> {amount?.toLocaleString()} 
                                            {productId == body.packageId &&
                                                <span>
                                                    <CheckCircle className="success" />
                                                </span>
                                                } 
                                            </p>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                       
                        <div className="userIptContent">

                            <div className="userIpt">
                                <input 
                                    type="text"
                                    name="number"
                                    autoComplete="off"
                                    value={body.number}
                                    placeholder="Smart Card Number"
                                    onChange={e => setBody({
                                        ...body,
                                        number: e.target.value
                                    })}
                                />
                                <select 
                                    name="invoicePeriod" 
                                    value={body.invoicePeriod}
                                    onChange={e =>setBody({
                                        ...body,
                                        invoicePeriod: e.target.value
                                    })}
                                >
                                    <option value="">Select Duration</option>
                                    <option value={1}>One Month</option>
                                    <option value={2}>Two Months</option>
                                    <option value={3}>Three Months</option>
                                    <option value={4}>Four Months</option>
                                    <option value={5}>Five Months</option>
                                </select>
                               
                            </div>
                            {
                                active !== 'STARTIMES' &&
                            
                                <div className="userIpt">
                                    <select 
                                        name=""
                                        value={body.paymentType}
                                        onChange={e => setBody({
                                            ...body,
                                            paymentType: e.target.value
                                        })}
                                    >
                                        <option value="">Select</option>
                                        <option value="renewPackage">Renew Package</option>
                                        <option value="changePackage">Change Page</option>
                                    </select>
                                </div>
                            }
                        </div>

                        <div className="proBtn">
                            <button
                                onClick={handleProceed}
                            >
                                {isLoading ? 'Proceeding...': 'Proceed'}
                            </button>
                        </div>

                    </div>


                </div>

            </div>

        </Fragment>

    )

}

export default CableContent