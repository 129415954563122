import { React, Fragment, useState, useEffect } from "react"
import DashboardLeft from "../components/DashboardLeft"
import DashboardRight from "../components/DashboardRight"
import DashboardCenter from "../components/DashboardCenter"
import { getAllTransactions } from "../customHooks/transaction"
import { useDispatch, useSelector } from "react-redux"
import { selectToken } from "../app/slices/userSlice"
import "../css/mediaQuery.css"
import { selectToggle } from "../app/slices/uiSlice"

const Dashboard = () => {
    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const [show, setShow] = useState()
    const toggle = useSelector(selectToggle)
    // const [showRes, setShowRes] = useState(true)
    useEffect(() => {
        if(!token) return 
        getAllTransactions(token, 1, 0, dispatch)
    }, [token]) 
    return (

        <Fragment>
            
            <div className="dashboardWrapper">
              
                <DashboardLeft />

                <main className="mainWrapper">                        
                        
                    <div className="centerContainer">

                        <DashboardCenter />
                        
                    </div>
                    
                    {
                        toggle &&
                        <div className="rightWrap">
                            <DashboardRight /> 
                        </div>
                    }
                       
                </main>

            </div>

        </Fragment>

    )

}

export default Dashboard