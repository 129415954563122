import React, { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { setIsMobile } from '../../app/slices/uiSlice'

const Drawer = ({children}) => {
    const dispatch = useDispatch()
    return (
        <Fragment>
            <section className='drawerContainer'
                onClick={() =>dispatch(setIsMobile(false))}
            >
                <div className='drawerBody'>
                    {children}
                </div>
            </section>
        </Fragment>
    )
}

export default Drawer