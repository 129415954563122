import { React, Fragment, useState } from "react"
import FeedHeader from "./global/FeedHeader";

const ProfileContent = () => {
    
    return (

        <Fragment>

            <div className="dataContWrap">
                
                <FeedHeader title="Profile" />

                <div className="dataMain">

                    <div className="profDetails">
                        
                    <h4 className="passUpdate">Password update</h4>

                        <div className="userIpt">
                            <input 
                                type="password"
                                placeholder="Old password"
                                autoComplete="off"
                            />
                        </div>
                        
                        <div className="userIpt">
                            <input 
                                type="password"
                                placeholder="New password"
                                autoComplete="off"
                            />
                        </div>
                        
                        <div className="userIpt">
                            <input 
                                type="password"
                                placeholder="Re-type New password"
                                autoComplete="off"
                            />
                        </div>
                        
                        <div className="proBtn">
                            <button>Proceed</button>
                        </div>
                        
                    </div>

                </div>
                

            </div>

        </Fragment>

    )

}

export default ProfileContent