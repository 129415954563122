import { IconButton } from '@mui/material'
import React, { Fragment } from 'react'
import {MenuIcon} from '@heroicons/react/solid'
import { ToUpperCase } from './func'
import { setToggle, selectToggle, setIsMobile, selectIsMobile } from '../../app/slices/uiSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser } from '../../app/slices/userSlice'
import { Close } from '@mui/icons-material'
import Currency from 'react-currency-formatter'

const UserCard = ({mobile}) => {
    const dispatch = useDispatch()
    const user = useSelector(selectUser)
    const toggle = useSelector(selectToggle)
    const isMobile = useSelector(selectIsMobile)
    return (
        <Fragment>
            <div className="userDropDetails">

                <div className="userIconWrap">
                    <div className="userIcon">
                        <img src={process.env.PUBLIC_URL + "./img/userDetail.png"} />
                    </div>   
                </div>

                {
                    user &&
                    <div className="userTxt">
                        <h3> {`${ToUpperCase(user?.firstName)} ${ToUpperCase(user?.lastName)}`} </h3>
                        {
                            user?.walletId &&
                            <h3 className='success'>
                            <Currency
                                currency='NGN'
                                quantity={user?.walletId?.walledBalance}
                            />
                            </h3>
                        }
                    </div>
                }
                {
                    !mobile ?
               
                <div>
                    <IconButton
                        onClick={() =>  dispatch(setToggle(!toggle))}
                    >
                        <MenuIcon  />
                    </IconButton>
                </div>
                :
                <div>
                    <IconButton
                        onClick={() =>  dispatch(setIsMobile(true))}
                    >
                       { 
                            isMobile ? <Close className='closeIcon' /> : <MenuIcon  />
                       }
                    </IconButton>
                </div>
                 }
            </div>
        </Fragment>
    )
}

export default UserCard