import { React, Fragment, useState, useEffect } from "react"
import {items} from "../components/LocalFetch" 
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { selectError, selectIsLoading, selectSuccess, setError } from "../app/slices/uiSlice";
import axios from "axios";
import ToolTip from "./global/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import Popover from "./global/Popover";
import { Button, Divider, IconButton, Tooltip as MuiTooltip } from "@mui/material";
import { CheckCircle, ResetTvRounded, VerifiedOutlined } from "@mui/icons-material";
import { selectToken } from "../app/slices/userSlice";
import FeedHeader from "./global/FeedHeader";
 

const AIRTIMES = [
    {
        id: 1,
        name: 'MTN',
        image: process.env.PUBLIC_URL + "./img/mtn.jpeg",
    },
    {
        id: 2,
        name: 'GLO',
        image: process.env.PUBLIC_URL + "./img/glo.jpeg",
    },
    {
        id: 3,
        name: 'AIRTEL',
        image: process.env.PUBLIC_URL + "./img/airt.jpeg",
    },
    {
        id: 4,
        name: '9MOBILE',
        image: process.env.PUBLIC_URL + "./img/mobile.jpeg",
    },
    // {
    //     id: 5,
    //     name: 'SMILE',
    //     image: process.env.PUBLIC_URL + "./img/smile.png"
    // }
]

const RenderAirtime = ({name, image, active, onClick}) => {
    return(
        <Fragment>
            <div 
                onClick={()=> onClick()}
                className={`airtimeLeft ${active == name ? 'active activeBorder': ''}`} 
                >
                <div className="airtimeLCont">
                    <div className="rechargeImg">
                        <img src={image} alt="img"/>  
                    </div>
                    <p>{name}</p>
                </div>
                <div className="aicone">
                    <div className="rechargeIcon">
                        <ShoppingCartIcon />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const DataContent = () => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const token = useSelector(selectToken)
    const [active, setActive] = useState()
    const Error = useSelector(selectError)
    const isLoading = useSelector(selectIsLoading)
    const success = useSelector(selectSuccess)
    const [products, setProducts] = useState([])

    const [body, setBody] = useState({
        index: '',
        image: '',
        amount: '',
        number: '',
        service: ''
    })

    const handleGetProducts = async() => {
        try {
            setProducts([])
            setBody({
                ...body,
                index: ''
            })
            dispatch(setError(null))
            const { data, status} = await axios.get(`/airtime/${active}_DATA`,{
                headers: {
                    Authorization: `ApiKey ${token}`
                }
            })
            if(data?.products?.length > 0) return setProducts(data?.products)
        } catch (error) {
            dispatch(setError(error?.response?.data?.message))
        }
    }

    const handleProceed = () => {
        const { amount, number } = body
        if(!number) return dispatch(setError('Phone number is required'))
        if(!amount) return dispatch(setError('Amount is required'))

        setOpen(true)

    }

    const handleBuyAirtime = () => {
        return
    }

    useEffect(() => {
        if(!active) return
        handleGetProducts()
    }, [active])

    return (

        <Fragment>
            {
                Error &&
                <ToolTip error message={Error} />
            }
            {
                success &&
                <ToolTip success message={success} />
            }

            {   
                open &&
                <Popover 
                    small
                    setOpen={() =>(
                           setProducts([]),
                           setOpen(false)
                        )
                    }
                >
                    <section
                        className="cardContainer"
                    >
                        <div
                            className="cardData"
                        >
                            
                            <section className="cardHeader">
                               <img src={body?.image} />
                            </section>
                            
                            <section className="padding" />
                            <section className="cardHeader">
                                <h3>Details</h3>
                            </section>
                            <Divider />
                            <div>
                                <p>Amoun:</p>
                                <p className="error"> 
                                   <strong>
                                    <span>&#x20A6; </span>
                                    {body.amount?.toLocaleString()} 
                                   </strong>
                                </p>
                            </div>
                            <div>
                                <p>Phone Number:</p>
                                <p> {body.number} </p>
                            </div>
                            <Divider />
                           
                            <Fragment>
                                {/* {
                                   
                                    <section className="cardHeader">
                                        <h3 className="success"> {'success'} </h3>
                                    </section>
                                } */}

                                {    
                                   
                                    <Fragment>
                                        <section className="cardHeader">
                                            <Button
                                                size="large"
                                                variant="contained"
                                                onClick={handleBuyAirtime}
                                            >
                                                {isLoading ? 'Pay...' : 'Pay'}
                                            </Button>
                                        </section>
                                    </Fragment>
                                }
                            </Fragment>
                            <section className="cardFooter">
                                <MuiTooltip title='Reset and return'>
                                   <dir>
                                   <IconButton
                                        onClick={() => (
                                           setProducts([]),
                                           setOpen(false)
                                        )}
                                   >
                                        <ResetTvRounded />
                                    </IconButton>
                                   </dir>
                                </MuiTooltip>
                            </section>
                            
                        </div>
                    </section>
                       
                </Popover>
            }

            <div className="dashboardCenter">

                <FeedHeader title="Data" />

                <div className="airtimeMain">

                    <div className="airtimeLeftWrap">
                        {
                            AIRTIMES?.map((item, index) => {
                                return <RenderAirtime 
                                    {...item}
                                    key={index*3467}
                                    active={active}
                                    onClick={() =>(
                                        setActive(item.name),
                                        setBody({
                                            ...body,
                                            image: item.image
                                        })
                                    )}
                                />
                            })
                        }
                    </div>
                    <div className="airtimeRight">

                        <div className="productContainer">
                            {products?.map((airtime, index)=> {
                                const {amount, service} = airtime
                                return (
                                    <div 
                                        key={index} 
                                        onClick={() => (
                                            setBody({
                                                ...body,
                                                index: index+1,
                                                amount: amount
                                            })
                                        )}
                                        className={`dataItem ${body.index == index+1 ? 'active activeBorder': ''}`}>
                                        <h3 className="success"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <span>&#x20A6; </span>
                                            {amount?.toLocaleString()}
                                            {body.index == index+1 && !service &&
                                            <span>
                                                <CheckCircle className="success" />
                                            </span>
                                            } 
                                        </h3>
                                        <p
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: body.index == index+1 && service ? 'space-between' :'center'
                                            }}
                                        > {service} 
                                           {body.index == index+1 && service &&
                                            <span>
                                                <CheckCircle className="success" />
                                            </span>
                                            } 
                                        </p>
                                    </div>
                                )}
                            )}
                        </div>

                        <div className="userIptContent">

                            <div className="userIpt">
                                <input 
                                    type="text"
                                    value={body.number}
                                    autoComplete="off"
                                    onChange={e => setBody({
                                        ...body,
                                        number: e.target.value
                                    })}
                                    placeholder="Phone number"
                                />
                            </div>

                            <div className="userIpt">
                                <input 
                                    type="number"
                                    value={body.amount}
                                    placeholder="Amount"
                                    autoComplete="off"
                                    onChange={e => setBody({
                                        ...body,
                                        amount: e.target.value
                                    })}
                                />
                            </div>

                        </div>

                        <div className="proBtn">
                            <button
                                onClick={handleProceed}
                            >
                                {isLoading ? 'Proceeding...': 'Proceed'}
                            </button>
                        </div>
                       
                    </div>

                </div>

            </div>

        </Fragment>

    )

}

export default DataContent