import { React, Fragment, useEffect } from 'react'
import Charts from './Charts';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken, selectUser } from '../app/slices/userSlice';
import { ToUpperCase } from './global/func';
import { selectTransactions } from '../app/slices/transactionSlice';
import { getAllTransactions } from '../customHooks/transaction';
import { selectToggle, setToggle, } from '../app/slices/uiSlice';
import FeedHeader from './global/FeedHeader';

const DashboardCenter = () => {
    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const user = useSelector(selectUser)
    const toggle = useSelector(selectToggle)
    const transactions = useSelector(selectTransactions)
    const handleTXFilter = (data) => {
        if(data?.length < 0 ) return 
        const txPending = data?.filter(item =>item.tranStatus == 2)
        const txCompleted = data?.filter(item =>item.tranStatus == 1)
        const txCanceled = data?.filter(item =>item.tranStatus == 3)

        return {
            totalPending: txPending?.length,
            totalCanceled: txCanceled?.length,
            totalCompleted: txCompleted?.length
        }

    }
    useEffect(() => {
        getAllTransactions(token, 1, 0, dispatch)
    }, [user])

    return (

        <Fragment>
            
            <div className="dashboardCenter">
              
                <FeedHeader title="Dashboard" />

                <div style={{flex: 1}}>

                    <div className="welcomeRow">
                        <div className="welcomeTxt">
                            <h2>Hello { `${ToUpperCase(user?.firstName)} ${ToUpperCase(user?.lastName)}` }</h2>   
                            <p>welcome back to your dashboard</p>
                        </div>
                        <div className="welcomeImg">
                            <div className="welcomeImgCont">
                                <img src={process.env.PUBLIC_URL + "./img/u.svg"} alt="img" />    
                            </div>    
                        </div>
                    </div> 
                    
                    <div className="transactionCards">
                        
                        <div className="totalDeb all">
                            <p>All Transactions</p>
                            <h4>{transactions?.length}</h4>
                        </div>
                        
                        <div className="totalDeb pending">
                            <p>Pending Transactions</p>
                            <h4>{handleTXFilter(transactions?.data).totalPending}</h4>
                        </div>

                        <div className="totalDeb completed">
                            <p>Completed Transactions</p>
                            <h4>{handleTXFilter(transactions?.data).totalCompleted}</h4>
                        </div>

                        

                        <div className="totalDeb canceled">
                            <p>Canceled Transactions</p>
                            <h4>{handleTXFilter(transactions?.data).totalCanceled}</h4>
                        </div>

                    </div>

                    <div className="profileGrowth">

                        <h4>Transaction History</h4>

                        <Charts />                 

                    </div>
               
                </div>
                
            
            </div>

        </Fragment>

    )

}

export default DashboardCenter