import axios from "axios"
import { setTransactions } from "../app/slices/transactionSlice"
import { setError } from "../app/slices/uiSlice"

export const getAllTransactions = async (token, page, limit,dispatch) =>{
    try {
        if(!token) return
        const { data, status } = await axios.get(`/transaction/page/${page}/${limit}`, {
            headers: {
                Authorization: `ApiKey ${token}`
            }
        })
        if(status == 200) return dispatch(setTransactions(data))
    } catch (error) {
        dispatch(setError(error?.response?.data?.message))
    }
}